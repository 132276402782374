import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import ContactForm, { IContactModalState } from '../../components/contact-form'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import NewsQuoteBlock from '../../components/NewsQuoteBlock'
import Link from '../../components/Common/Link'
import { useTranslations } from '../../hooks/use-translations'

const SignalEnteredRussianSoftwareRegister: React.FC = () => {
  const { t } = useTranslations()
  const [modal, setModal] = React.useState<IContactModalState>()
  const images = useStaticQuery(imagesQuery)
  return (
    <>
      <SEO
        title="SIGNAL вошел в реестр российского ПО Минцифры"
        description="SIGNAL теперь доступен для работы с госкомпаниями, пройдя регистрацию в реестре российского ПО. Узнайте, как наше отечественные решения могут помочь в цифровизации строительных процессов."
      />
      <NewsSectionBlock date="02.09.2024">
        <Typography variant="h1" color="blue">
          SIGNAL вошел в реестр российского&nbsp;ПО&nbsp;Минцифры!
        </Typography>
        <Typography variant="body1">
          Мы доказали свою надежность и стабильность для российского рынка ПО,
          поэтому теперь можем работать с госкомпаниями!
        </Typography>
        <NewsQuoteBlock
          className="mt-16 mb-16"
          name="Александр Попов"
          position="Технический директор SIGNAL"
          text={`“Для некоторых наших клиентов важно, чтобы решение состояло в реестре отечественного ПО. Для кого-то это было камнем преткновения на этапе закупки. Но теперь мы перевернули этот камень и доступны для различных госкомпаний, экспертиз и учреждений. В ближайшее время уже несколько кейсов с такими компаниями опубликуем, следите за новостями”`}
          avatar={images.avatar.childImageSharp.gatsbyImageData}
        />
        <Typography variant="body1">
          Мы будем и дальше стараться для вас, наших клиентов, создавая лучший
          сервис по цифровизации строительных процессов в стране!
        </Typography>
        <Typography variant="body1">
          А протестировать продукты SIGNAL вы можете уже сейчас, оставив заявку
          на{' '}
          <Typography
            variant="body2"
            color="blue"
            onClick={() =>
              setModal({
                title: t('Try for free'),
                requestType: 'Trial',
              })
            }
          >
            сайте
          </Typography>
        </Typography>
        <Typography variant="body1">
          Наши сотрудники ответят на все ваши вопросы и покажут, чем в вашем
          случае будут полезны продукты SIGNAL
        </Typography>
        <Typography variant="body1">
          Бесплатный доступ предоставляется на целый месяц!
        </Typography>
        <Typography variant="body1">
          Больше новостей ищите в нашем телеграм-канале:{' '}
          <Link
            href="https://t.me/sgnlpro"
            rel="nofollow noreferrer"
            target="_blank"
          >
            https://t.me/sgnlpro
          </Link>
        </Typography>
      </NewsSectionBlock>
      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal.title}
          requestType={modal.requestType}
        />
      )}
    </>
  )
}

export default SignalEnteredRussianSoftwareRegister

const imagesQuery = graphql`
  query {
    avatar: file(relativePath: { eq: "author/Alexander_Popov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED)
      }
    }
  }
`
